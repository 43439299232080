import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';

import naverIcon from '../assets/images/marketImages/naver.png';
import coupangIcon from '../assets/images/marketImages/coupang.png';
import gmarketIcon from '../assets/images/marketImages/gmarket.png';
import auctionIcon from '../assets/images/marketImages/auction.png';
import ESMplusIcon from '../assets/images/marketImages/ESMplus.png';
import ablyIcon from '../assets/images/marketImages/ably.png';
import cafe24Icon from '../assets/images/marketImages/cafe24.png';
import { motion } from 'framer-motion/dist/framer-motion';
import * as api from '../controller/api';
import { useHistory } from 'react-router';
import { mobileAppBarHeight, webAppBarHeight } from '../widgets/MyAppBar';
import { colors } from '../style';
import useWindowSize from '../hooks/useWindowSize';
import { promotion_3o3 } from '../constants/urls';
import { useStoreValue } from '../contexts';

const reviewList = [
  {
    title: '신성이야님 후기',
    description: `여러 앱 써봤지만 단연 최곱니다. 디자인 인터페이스 깔끔하고. 속도도 정말빨라요.\n유료의 퀄리티를가진 무료앱이네요`,
  },
  {
    title: '애ㅖ싷ㄱㄷㄱㄷ그님',
    description: `한눈에 볼 수 있어서 너무 편하네요 어서 주문건이 많아서 더 이용해 보고싶어요!`,
  },
  {
    title: 'Jspman님',
    description: `쇼핑몰을 위한 정말 필요한 앱이네요.\n가려운 부분을 시원하게 긁어주는 앱입니다.`,
  },
  {
    title: 'Bomin Seungho님',
    description: `기존의 쇼핑몰 앱보다 훨씬 편리하고 이용하기 쉽게 잘 되어 있는 것 같습니다 ~~~!!!!!!\n다만 쇼핑몰 연동이 더 많이 되어 쓸 수있다면 더할 나위 없겠지만 그래도 현재 상태에서도 충분히 원하는 기능을\n직관적으로 쓸 수 있는 것 같습니다. 앞으로도 더욱 나아지기를 바래 봅니다`,
  },
  {
    title: '이연수님',
    description: `업데이트이후 더 깔해지고 정리된듯하여 편합니다. 지속적으로 관리해주시는 관리자님 덕분인듯합니다.\n앞으로도 더 많은 데이터 부탁드립니다.`,
  },
  {
    title: '이수희님',
    description: `사용자의 입장에서 고민한 흔적이 많이 느껴집니다. 개선의견도 빠르게 반영되서 만족하며 쓰고있습니다.\n번창하세요^^`,
  },
];

const sellkeyMenuList = [
  {
    title: '키워드 탐색',
    pcImage: 'images/pcSellkeyKeywordSearchImage.png',
    mobileImage: 'images/mobileSellkeyKeywordSearchImage.png',
  },
  {
    title: '키워드 분석',
    pcImage: 'images/pcSellkeyKeywordAnalyticsImage.png',
    mobileImage: 'images/mobileSellkeyKeywordAnalyticsImage.png',
  },
  {
    title: '상품 순위',
    pcImage: 'images/pcSellkeyProductRankingImage.png',
    mobileImage: 'images/mobileSellkeyProductRankingImage.png',
  },
  {
    title: '상품 모니터링',
    pcImage: 'images/pcSellkeyProductMonitoring.png',
    mobileImage: 'images/mobileSellkeyProductMonitoring.png',
  },
];

export const AnimationDiv = ({ children, style, className, delay = 0 }: any) => {
  //하단에서 올라오는 애니메이션 기존 값 initial={{ y: 300, opacity: 0 }}
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ y: 0, opacity: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0,
          duration: 1.8,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export const AnimationDivJustOpacity = ({ children, style, className, delay = 0 }: any) => {
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ opacity: 0.3 }}
      whileInView={{
        opacity: 1,
        transition: {
          // type: "spring",
          bounce: 0,
          duration: 1.0,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export const RightToLeftAnimationDiv = ({ children, style, className, delay = 0 }: any) => {
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ x: '100%' }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0,
          duration: 1.8,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

const checkMobile = () => {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf('android') > -1) {
    return 'android';
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    return 'ios';
  } else {
    return 'other';
  }
};

export const handleDownLoad = (type = 'auto', isNaver = false, is3o3 = false) => {
  const isDevice = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  if (isDevice && is3o3) {
    window.open(
      'https://sellerbox.page.link/?link=https://sellerbox.io/3o3_promotion2310&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_3o3&utm_medium=message&utm_source=3o3',
      '_blank'
    );
  } else if (isDevice && isNaver) {
    window.open(
      'https://sellerbox.page.link/?link=https://sellerbox.io&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_naver&utm_medium=cpc&utm_source=naver',
      '_blank'
    );
  } else {
    switch (type) {
      case 'auto':
        switch (checkMobile()) {
          case 'android':
            window.open(
              'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
              '_blank'
            );
            break;
          case 'ios':
            window.open(
              'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
              '_black'
            );
            break;
        }
        break;
      default:
        if (type === 'android') {
          window.open(
            'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
            '_blank'
          );
        } else {
          window.open(
            'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
            '_blank'
          );
        }
        break;
    }
  }
};

interface TextBoxProps {
  title: string;
  description: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const PcTextBox = ({
  title,
  description,
  descriptionStyle = {},
  titleStyle = {},
  style = { width: 520 },
}: TextBoxProps) => {
  return (
    <div style={{ ...style }}>
      <div
        style={{
          fontSize: 44,
          fontWeight: 800,
          lineHeight: '130%',
          marginBottom: 24,
          whiteSpace: 'pre-wrap',
          ...titleStyle,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: 22,
          fontWeight: 500,
          lineHeight: '160%',
          whiteSpace: 'pre-wrap',
          color: '#3D3D3D',
          ...descriptionStyle,
        }}
      >
        {description}
      </div>
    </div>
  );
};

export const MobileTextBox = ({
  title,
  description,
  descriptionStyle = {},
  titleStyle = {},
}: TextBoxProps) => {
  return (
    <div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 700,
          lineHeight: '28px',
          marginBottom: 12,
          whiteSpace: 'pre-wrap',
          ...titleStyle,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontWeight: 500,
          lineHeight: '160%',
          whiteSpace: 'pre-wrap',
          color: '#3D3D3D',
          fontSize: 14,
          ...descriptionStyle,
        }}
      >
        {description}
      </div>
    </div>
  );
};

interface DetailBoxProps {
  type?: 'leftText' | 'rightText';
  image: string;
  title: string;
  description: string;
}

export const PcDetailBox = ({ type = 'leftText', image, title, description }: DetailBoxProps) => {
  const TextComponent = <PcTextBox title={title} description={description} />;
  const ImageCompoment = <img src={image} style={{ width: 520, height: 400 }} />;
  return (
    <div
      style={{
        width: 1070,
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {type === 'leftText' ? (
        <Fragment>
          {TextComponent}
          <AnimationDiv>{ImageCompoment}</AnimationDiv>
        </Fragment>
      ) : (
        <Fragment>
          <AnimationDiv>{ImageCompoment}</AnimationDiv>
          {TextComponent}
        </Fragment>
      )}
    </div>
  );
};

export const MobileDetailBox = ({
  type = 'leftText',
  image,
  title,
  description,
}: DetailBoxProps) => {
  const TextComponent = <MobileTextBox title={title} description={description} />;
  const ImageCompoment = <img src={image} style={{ width: '100%' }} />;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Fragment>
        {TextComponent}
        <AnimationDiv>{ImageCompoment}</AnimationDiv>
      </Fragment>
    </div>
  );
};

const marketList = [
  {
    url: naverIcon,
    ko: '스마트스토어',
    en: 'SmartStore',
  },
  {
    url: coupangIcon,
    ko: '쿠팡',
    en: 'Coupang',
  },
  {
    url: gmarketIcon,
    ko: 'G마켓',
    en: 'G Market',
  },
  {
    url: auctionIcon,
    ko: '옥션',
    en: 'Auction',
  },
  {
    url: ESMplusIcon,
    ko: 'ESM PLUS',
    en: 'ESM PLUS',
  },
  {
    url: ablyIcon,
    ko: '에이블리',
    en: 'Ably',
  },
  {
    url: cafe24Icon,
    ko: '카페24',
    en: 'Cafe 24',
  },
  // {
  //     url: null,
  //     ko: "",
  //     en: "",
  // },
  // {
  //     url: null,
  //     ko: "",
  //     en: "",
  // },
];

const mobileTitleComponentList = [
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #00C82D 0%, #00D2C6 100%)',
        padding: '5px 9px',
        color: colors.gray.White,
      }}
    >
      판매
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #D74AFB 0%, #6B8CFF 100%)',
        padding: '5px 9px',
        color: colors.gray.White,
      }}
    >
      매출
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #EAD200 0%, #FF9A24 100%)',
        padding: '5px 9px',
        color: colors.gray.White,
      }}
    >
      정산
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #FF6A6A 0%, #FF87A4 100%)',
        padding: '5px 9px',
        color: colors.gray.White,
      }}
    >
      리뷰
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #26CAFE 0%, #6B8CFF 100%)',
        padding: '5px 9px',
        color: colors.gray.White,
      }}
    >
      경쟁
    </div>
  </AnimationDivJustOpacity>,
];

const pcTitleComponentList = [
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #00C82D 0%, #00D2C6 100%)',
        padding: 10,
        color: colors.gray.White,
      }}
    >
      판매
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #D74AFB 0%, #6B8CFF 100%)',
        padding: 10,
        color: colors.gray.White,
      }}
    >
      매출
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #EAD200 0%, #FF9A24 100%)',
        padding: 10,
        color: colors.gray.White,
      }}
    >
      정산
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #FF6A6A 0%, #FF87A4 100%)',
        padding: 10,
        color: colors.gray.White,
      }}
    >
      리뷰
    </div>
  </AnimationDivJustOpacity>,
  <AnimationDivJustOpacity>
    <div
      style={{
        marginRight: 8,
        borderRadius: 2,
        background: 'linear-gradient(90deg, #26CAFE 0%, #6B8CFF 100%)',
        padding: 10,
        color: colors.gray.White,
      }}
    >
      경쟁
    </div>
  </AnimationDivJustOpacity>,
];

export const SellerboxAppPage = () => {
  const { isEnteredByNaver } = useStoreValue();
  const history = useHistory();
  const isMobile = useMediaQueryForMobile();
  const [rawMarkets, setRawMarkets] = useState<any>([]);
  const [markets, setMarkets] = useState<any>([]);
  const dimension = useWindowSize();
  const [reviewPage, setReviewPage] = useState(0);
  const [reviewPageMouseEnterIndex, setReviewPageMouseEnterIndex] = useState<0 | 1 | null>(null);
  const [reviewVisible, setReviewVisible] = useState(true); //리뷰 리렌더링을 통한 애니메이션 구현용
  const [selectedMenu, setSelectedMenu] = useState(sellkeyMenuList[0]);
  const [sellkeyPreviewVisible, setSellkeyPreviewVisible] = useState(true); //셀키 미리보기 리렌더링을 통한 애니메이션 구현용
  const [titleComponentIndex, setTitleComponentIndex] = useState(0);
  const [titleComponentVisible, setTitleComponentVisible] = useState(true); //셀키 미리보기 리렌더링을 통한 애니메이션 구현용

  const is3o3Promotion = window.location.href.includes(promotion_3o3);

  const dimensionChangeFlag = parseInt((dimension.width / 10).toFixed(0)) * 10;

  useEffect(() => {
    let timer = setInterval(() => {
      setTitleComponentIndex(
        (preTitleComponentIndex) =>
          (preTitleComponentIndex + 1) %
          (isMobile ? pcTitleComponentList : mobileTitleComponentList).length
      );
    }, 2200);
  }, []);

  useEffect(() => {
    setTitleComponentVisible(false);
  }, [titleComponentIndex]);

  useEffect(() => {
    if (!reviewVisible) {
      setReviewVisible(true);
    }
    if (!sellkeyPreviewVisible) {
      setSellkeyPreviewVisible(true);
    }
    if (!titleComponentVisible) {
      setTitleComponentVisible(true);
    }
  }, [reviewVisible, sellkeyPreviewVisible, titleComponentVisible]);

  const updateIconsPosition = () => {
    setMarkets((prevMarkets) =>
      prevMarkets.map((market) => {
        let newPosition = getNextPosition({
          left: market?.left,
          top: market?.top,
          defaultLeft: market?.defaultLeft,
          defaultTop: market?.defaultTop,
        });
        return {
          ...market,
          left: newPosition.left,
          top: newPosition.top,
        };
      })
    );
  };

  const updateMarkets = () => {
    setMarkets(
      rawMarkets
        .filter((d) => d.MarketSeqNo !== 99 && !d.MarketName.includes('지원종료'))
        .map((market) => {
          return {
            ...market,
            defaultLeft: dimension.width * Math.random(),
            defaultTop: isMobile ? 660 * Math.random() : 930 * Math.random() - 40,
            left: 0,
            top: 0,
          };
        })
    );
  };

  const getNextPosition = (position) => {
    const isMobile = dimension.width < 600; //setInterval에 들어가는거라 hook 사용시 오류 발생
    let newPosition = {
      left: position.left,
      top: position.top,
      defaultLeft: position.defaultLeft,
      defaultTop: position.defaultTop,
    };
    let offsetPosition = {
      left: Math.random() * (isMobile ? 200 : 500),
      top: Math.random() * (isMobile ? 200 : 500),
    };
    let currentLeft = newPosition.defaultLeft + newPosition.left;
    let currentTop = newPosition.defaultTop + newPosition.top;
    if (currentLeft - offsetPosition.left < 0) {
      newPosition.left += offsetPosition.left;
    } else if (currentLeft + offsetPosition.left > (isMobile ? dimension.width : 1440) - 40) {
      newPosition.left -= offsetPosition.left;
    } else {
      if (Math.random() > 0.5) {
        newPosition.left -= offsetPosition.left;
      } else {
        newPosition.left += offsetPosition.left;
      }
    }

    if (currentTop - offsetPosition.top < 0) {
      newPosition.top += offsetPosition.top;
    } else if (currentTop + offsetPosition.top > (isMobile ? 640 : 930)) {
      newPosition.top -= offsetPosition.top;
    } else {
      if (Math.random() > 0.5) {
        newPosition.top -= offsetPosition.top;
      } else {
        newPosition.top += offsetPosition.top;
      }
    }
    return newPosition;
  };

  useEffect(() => {
    api.GetMarketModel({}).then((res: any) => {
      let { code, data, message } = res.data;
      setRawMarkets(data.filter((d) => d.MarketSeqNo !== 99 && !d.MarketName.includes('지원종료')));
    });
  }, []);

  useEffect(() => {
    updateMarkets();
    updateIconsPosition();
  }, [dimensionChangeFlag, rawMarkets]);

  // useEffect(()=>{
  //     if(markets && !markets[0].left){
  //         updateIconsPosition();
  //     }
  // },[markets]);

  useEffect(() => {
    const intervalId = setInterval(updateIconsPosition, 2500); // 1초마다 위치 업데이트
    return () => clearInterval(intervalId); // 컴포넌트가 언마운트될 때 clearInterval
  }, []);

  return (
    <WholePageWrapper id="hi">
      {isMobile ? (
        <MobilePageWrapper>
          <MobileContentGrayWrapperWithNoPadding>
            {is3o3Promotion && (
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '40px 20px 28px 20px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    fontFamily: 'GmarketSans',
                    fontSize: 14,
                    color: '#FFA800',
                    fontWeight: 500,
                    lineHeight: '140%',
                  }}
                >
                  {'삼쩜삼 사업자 회원님께 셀러박스를 소개합니다.'}
                </div>
                <div style={{ height: 8 }} />
                <div
                  style={{
                    fontFamily: 'GmarketSansBold',
                    letterSpacing: '-0.69px',
                    whiteSpace: 'pre-wrap',
                    fontSize: 23,
                    lineHeight: '140%',
                    color: '#1A1A1A',
                  }}
                >
                  {`여러 쇼핑몰 데이터\n셀러박스 하나로 분석하세요!`}
                </div>
                <div style={{ height: 14 }} />
                <div style={{ width: '100%', display: 'flex', flex: '0 0 auto' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 3,
                      alignItems: 'center',
                      padding: '6px 10px',
                      borderRadius: 10,
                      backgroundColor: '#FFA800',
                      color: '#FFFFFF',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'GmarketSansBold',
                        fontSize: 12,
                        lineHeight: '140%',
                        letterSpacing: '-0.36px',
                      }}
                    >
                      온라인 셀러 만족도 4.8점
                    </div>
                    <div
                      style={{
                        fontFamily: 'GmarketSans',
                        fontSize: 10,
                        lineHeight: '140%',
                        letterSpacing: '-0.3px',
                        opacity: 0.6,
                      }}
                    >
                      (iOS 기준)
                    </div>
                  </div>
                </div>
                <div style={{ height: 25.25 }} />
                <img src="/images/mobile3o3Logo.png" style={{ width: 165 }} />
              </div>
            )}
            <div
              style={{
                width: '100%',
                height: 800,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div style={{ height: 200 }} />

              <div
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: '50%',
                  backgroundColor: '#FF9C66',
                  position: 'absolute',
                  left: -57,
                  top: 43,
                }}
              />
              <div
                style={{
                  width: 222,
                  height: 76,
                  borderRadius: 57,
                  backgroundColor: '#FFBB7C',
                  position: 'absolute',
                  right: -83,
                  top: 550,
                }}
              />

              {markets?.map((market) => (
                <motion.div
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    top: market?.defaultTop,
                    left: market?.defaultLeft,
                  }}
                  animate={{
                    x: market?.left,
                    y: market?.top,
                  }}
                  transition={{ duration: 2.5 }}
                >
                  <img src={market.MarketIconUrl} style={{ width: 40 }} />
                </motion.div>
              ))}

              <div
                style={{
                  width: 59,
                  height: 59,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  left: 9,
                  top: 101,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 59,
                  height: 59,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  right: 13,
                  top: 47,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 103,
                  height: 103,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.50)',
                  position: 'absolute',
                  right: 20,
                  top: 568,
                }}
                className="backdropFilter27"
              />

              <div
                style={{
                  fontSize: 30,
                  fontWeight: 800,
                  lineHeight: '30px',
                  textAlign: 'center',
                  zIndex: 1,
                  borderRadius: 12,
                  backgroundColor: '#F5F5F5',
                }}
              >
                <div
                  style={{
                    marginBottom: 15,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {titleComponentVisible ? (
                    mobileTitleComponentList[titleComponentIndex]
                  ) : (
                    <div style={{ width: 69.86 }} />
                  )}
                  <span>데이터 단숨에 연결</span>
                </div>
                <div>AI 전략을 더해 단번에 성장</div>
              </div>

              <div
                className="flex"
                style={{ gap: 10, marginTop: 42, alignItems: 'center', zIndex: 1 }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
              </div>

              {/* <div style={{ height: 489 }} /> */}
            </div>

            <div style={{ fontSize: 24, fontWeight: 700, lineHeight: '130%', color: '#1A1A1A' }}>
              아직도 고민하고 계신가요?
            </div>

            <div
              style={{
                marginTop: 36,
                marginBottom: 120,
                fontSize: 20,
                fontWeight: 700,
                lineHeight: '130%',
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
                color: '#DFDFDF',
              }}
            >
              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'center' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: 0,
                    width: 346 * 8,
                    whiteSpace: 'nowrap',
                    animation: 'carousel346 4s linear infinite',
                  }}
                >
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                  <div style={{ width: 346 }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요.
                  </div>
                </div>
              </div>

              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: 0,
                    width: 693 * 5,
                    whiteSpace: 'nowrap',
                    animation: 'carousel693 8s linear infinite',
                  }}
                >
                  <div style={{ width: '366px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어.
                  </div>
                  <div style={{ width: '327px' }}>
                    스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게 너무
                    번거로워.
                  </div>
                  <div style={{ width: '366px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어.
                  </div>
                  <div style={{ width: '327px' }}>
                    스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게 너무
                    번거로워.
                  </div>
                  <div style={{ width: '366px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어.
                  </div>
                  <div style={{ width: '327px' }}>
                    스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게 너무
                    번거로워.
                  </div>
                  <div style={{ width: '366px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어.
                  </div>
                  <div style={{ width: '327px' }}>
                    스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게 너무
                    번거로워.
                  </div>
                  <div style={{ width: '366px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어.
                  </div>
                  <div style={{ width: '327px' }}>
                    스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게 너무
                    번거로워.
                  </div>
                </div>
              </div>

              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: 438 * 8,
                    padding: 0,
                    whiteSpace: 'nowrap',
                    animation: 'carousel438 5s linear infinite',
                  }}
                >
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                  <div style={{ width: '438px' }}>
                    유료 프로그램은 부담스러운데 <span style={{ color: '#F6C518' }}>무료</span>{' '}
                    프로그램은 없을까?
                  </div>
                </div>
              </div>
            </div>
          </MobileContentGrayWrapperWithNoPadding>

          <MobileContentWhiteWrapperWithNoPadding style={{ padding: '0px 30px' }}>
            <div style={{ height: 100 }} />
            <div style={{ fontSize: 20, fontWeight: 700, lineHeight: '130%', color: '#1A1A1A' }}>
              <span style={{ color: '#FF7F00' }}>셀러박스</span>가 셀러님 고민 없애드릴게요
            </div>
            <div style={{ height: 30 }} />
            <div
              style={{
                width: '100%',
                display: 'flex',
                borderRadius: 40,
                backgroundColor: '#F9F9FA',
              }}
            >
              <img src="images/mobileSellerboxAppMainExampleImage.png" style={{ width: '100%' }} />
            </div>
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`주문이 들어오면\n실시간 알림을 보내드려요`}
              description={`연결한 마켓에서 주문이 왔을 때\n셀러박스는 놓치지 않고 알려드려요`}
              image="images/mobileSellerboxAppScreen1ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`오늘 입금되는 돈\n오전 9시에 알려드려요`}
              description={`마켓마다 다른 정산 주기\n월별 입금액을 미리 확인하세요`}
              image="images/mobileSellerboxAppScreen2ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`똑같은 리뷰 답글은 그만\nAI 답변으로 재구매를 높여요`}
              description={`구매자의 긍정표현, 부정표현을 분석하고\nAI가 적절한 답변을 생성해드려요`}
              image="images/mobileSellerboxAppScreen3ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`잦은 반품과 취소\n원인을 분석하고 대응하세요`}
              description={`유쾌하지 않은 반품과 취소\n구매자 사유를 통계로 보여드려요`}
              image="images/mobileSellerboxAppScreen4ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`기간별 판매금액과 건수\n한 눈에 확인하세요`}
              description={`1주, 1개월, 3개월 판매 추이를 확인하고\n다른 셀러와 비교해보세요`}
              image="images/mobileSellerboxAppScreen5ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 60 }} />
            <MobileDetailBox
              title={`매일 변하는 키워드 별\n노출 순위를 확인하세요`}
              description={`상품의 순위 변동을 실시간으로 추적하고,\n시장 변화에 능동적으로 대응하세요`}
              image="images/mobileSellerboxAppScreen6ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 100 }} />

            <div style={{ width: '100vw', backgroundColor: '#F9F9F9', textAlign: 'center' }}>
              <div style={{ height: 36 }} />
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 6, alignItems: 'center' }}
              >
                <img src="images/IconMarket.png" style={{ width: 30 }} alt="" />
                <div
                  style={{ fontSize: 20, fontWeight: 700, lineHeight: '100%', color: '#3D3D3D' }}
                >
                  연동 가능한 상점
                </div>
                <div
                  style={{ fontSize: 14, fontWeight: 600, lineHeight: '24px', color: '#939393' }}
                >
                  연동 가능 스토어를 지속적으로 확대 중입니다!
                </div>
              </div>
              <div style={{ height: 36 }} />
              <div
                className="flexColumnWrapper"
                style={{
                  width: '100%',
                  fontSize: 14,
                  overflow: 'hidden',
                  alignItems: 'flex-start',
                  fontWeight: 600,
                  lineHeight: '22px',
                  color: '#939393',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: markets.slice(0, Math.ceil(markets.length / 2)).length * 100 * 2 + 'px',
                    animation: 'bannermove 10s linear infinite',
                  }}
                >
                  <Fragment>
                    {markets.slice(0, Math.ceil(markets.length / 2)).map((market: any) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }}>
                          <img
                            src={market.MarketIconUrl}
                            style={{ width: 80, height: 80 }}
                            alt=""
                          />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                    {markets.slice(0, Math.ceil(markets.length / 2)).map((market: any) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }}>
                          <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                </div>
                <div style={{ height: 36 }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: markets.slice(Math.ceil(markets.length / 2)).length * 100 * 2 + 'px',
                    animation: 'bannermove 10s linear infinite',
                  }}
                >
                  <Fragment>
                    {markets.slice(Math.ceil(markets.length / 2)).map((market: any) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }}>
                          <img
                            src={market.MarketIconUrl}
                            style={{ width: 80, height: 80 }}
                            alt=""
                          />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                    {markets.slice(Math.ceil(markets.length / 2)).map((market: any) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }}>
                          <img
                            src={market.MarketIconUrl}
                            style={{ width: 80, height: 80 }}
                            alt=""
                          />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                </div>
              </div>
              <div style={{ height: 120 }} />
            </div>
            <div style={{ height: 80 }} />
            <div style={{ width: '100%' }}>
              <MobileTextBox
                title={`더 많은 기능`}
                description={`셀러박스에서 더 많은 기능들을\n직접 이용해 보세요`}
              />
              <div style={{ height: 48 }} />
              <AnimationDiv>
                <img
                  src="images/mobileSellerboxAppMoreFuntionList1Image.png"
                  style={{ width: 'calc(100% - 17px)' }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/mobileSellerboxAppMoreFuntionList2Image.png"
                  style={{ width: '100%' }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/mobileSellerboxAppMoreFuntionList3Image.png"
                  style={{ width: '100%' }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/mobileSellerboxAppMoreFuntionList4Image.png"
                  style={{ width: 'calc(100% - 13px)' }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/mobileSellerboxAppMoreFuntionList5Image.png"
                  style={{ width: 'calc(50% - 30px)' }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 66 }} />
            </div>
          </MobileContentWhiteWrapperWithNoPadding>

          <MobileContentBlackWrapperWithNoPadding>
            <div
              style={{
                width: '100%',
                height: 380,
                padding: '50px 30px 36px 30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {reviewVisible && (
                <motion.div
                  style={{ position: 'relative', left: -30 }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    x: 30,
                    opacity: 1,
                  }}
                  transition={{ duration: 0.7 }}
                >
                  <div>
                    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                      <img src="images/unkownUserImage.png" style={{ width: 36 }} alt="" />
                      <div
                        style={{ color: '#FFF', fontSize: 16, fontWeight: 500, lineHeight: '160%' }}
                      >
                        {reviewList[reviewPage].title}
                      </div>
                    </div>
                    <div style={{ height: 12 }} />
                    <div
                      style={{
                        color: '#FFF',
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: '24px',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {reviewList[reviewPage].description}
                    </div>
                  </div>
                </motion.div>
              )}
              <div style={{ height: 30 }} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderRightWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 0 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === 0 ? reviewList.length - 1 : preReviewPage - 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(0);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewBackArrowImage.png"
                    style={{ width: 12 }}
                  />
                </div>
                <div style={{ height: 48, width: 1, backgroundColor: '#C8C8C8' }} />
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderLeftWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 1 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === reviewList.length - 1 ? 0 : preReviewPage + 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(1);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewForwardArrowImage.png"
                    style={{ width: 12 }}
                  />
                </div>
              </div>
            </div>
          </MobileContentBlackWrapperWithNoPadding>

          <MobileContentGrayWrapperWithNoPadding style={{ padding: '0px 30px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div style={{ height: 100 }} />

              <div style={{ width: '100%' }}>
                <div
                  style={{ fontSize: 20, fontWeight: 700, lineHeight: '28px', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <br />
                  <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서 만들었어요
                </div>
                <div style={{ height: 12 }} />
                <div
                  style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 40 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage1.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage2.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage3.png" style={{ width: '100%' }} />
                </AnimationDiv>
              </div>
              <div style={{ height: 100 }} />
            </div>
          </MobileContentGrayWrapperWithNoPadding>

          <MobileContentWhiteWrapperWithNoPadding
            style={{ width: '100%', backgroundColor: '#FBFBFB' }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 80,
                paddingBottom: 100,
              }}
            >
              <div>
                <div style={{ padding: '0px 30px' }}>
                  <div
                    style={{ color: '#3D3D3D', fontSize: 14, fontWeight: 500, lineHeight: '160%' }}
                  >
                    셀러박스 키위
                  </div>
                  <div style={{ height: 12 }} />
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                      color: '#1A1A1A',
                      fontSize: 20,
                      fontWeight: 700,
                      lineHeight: '28px',
                    }}
                  >
                    {`키워드 검색과\n경쟁상점 분석을 한번에`}
                  </div>
                  <div style={{ height: 12 }} />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open('https://sellkey.sellerbox.io', '_self');
                    }}
                  >
                    <span
                      style={{
                        color: '#1A1A1A',
                        fontSize: 14,
                        fontWeight: 700,
                        lineHeight: '22px',
                        marginRight: 4,
                      }}
                    >
                      바로가기
                    </span>
                    <img src="images/ShortcutImage.png" style={{ width: 24 }} />
                  </div>
                  <div style={{ height: 36 }} />
                </div>
                <div
                  style={{
                    touchAction: 'pan-x',
                    display: 'flex',
                    gap: 12,
                    overflowX: 'scroll',
                    width: '100%',
                    padding: '0px 30px',
                  }}
                >
                  {sellkeyMenuList.map((menu, menuIndex) => (
                    <div
                      key={menuIndex}
                      onClick={() => {
                        setSelectedMenu(menu);
                        setSellkeyPreviewVisible(false);
                      }}
                      style={{
                        touchAction: 'pan-x',
                        cursor: 'pointer',
                        padding: '7px 12px',
                        borderRadius: 10,
                        flex: '0 0 auto',
                        backgroundColor:
                          selectedMenu.title === menu.title ? '#708FFF' : 'transparent',
                        boxShadow:
                          selectedMenu.title === menu.title
                            ? '0px 4px 14px 0px rgba(49, 76, 175, 0.25)'
                            : 'none',
                        color: selectedMenu.title === menu.title ? '#FFFFFF' : '#3D3D3D',
                        fontWeight: selectedMenu.title === menu.title ? 700 : 500,
                        fontSize: 15,
                        lineHeight: '160%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      {menu.title}
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  padding: '30px 30px',
                  width: '100%',
                  height: 'calc((100vw - 60px) * 1.56333)',
                }}
              >
                {sellkeyPreviewVisible ? (
                  <AnimationDivJustOpacity>
                    <img
                      src={selectedMenu.mobileImage}
                      style={{
                        borderRadius: 20,
                        width: '100%',
                        boxShadow: '-11.98793px 14.98491px 21.97787px 0px rgba(0, 0, 0, 0.05)',
                      }}
                    />
                  </AnimationDivJustOpacity>
                ) : (
                  <div style={{ width: '100%', height: '100%' }} />
                )}
              </div>
            </div>
          </MobileContentWhiteWrapperWithNoPadding>

          <div className="flexRowWrapper" style={{ width: '100%', position: 'relative' }}>
            <img
              src="images/mobile-home-last-background.png"
              style={{
                backgroundSize: 'contain',
                height: 500,
                position: 'absolute',
                zIndex: 0,
                width: '100%',
              }}
            />
            <div
              style={{
                width: '100%',
                height: 500,
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 700, lineHeight: '150%', color: '#DFDFDF' }}>
                판매의 성장을 스마트하게
              </div>
              <div style={{ height: 12 }} />
              <div style={{ fontSize: 30, fontWeight: 800, lineHeight: '150%', color: '#F5F5F5' }}>
                셀러박스와 함께 시작해 보세요
              </div>
              <div
                className="flex"
                style={{
                  gap: 10,
                  marginTop: 16,
                  marginBottom: 20,
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: '22px',
                  color: '#FAFAFA',
                  marginBottom: 12,
                }}
              >
                셀러박스에 원하는 기능이 있다면 저희에게 알려주세요!
              </div>
              <img
                className="pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push('/clients/contact');
                }}
                src="images/mobile-home-last-button.png"
                style={{ width: 136 }}
              />
            </div>
          </div>
        </MobilePageWrapper>
      ) : (
        <PcPageWrapper id="aa">
          <MobileContentGrayWrapperWithNoPadding>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div style={{ height: 290 }} />

              <div
                style={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  backgroundColor: '#FF9C66',
                  position: 'absolute',
                  left: -65,
                  top: 0,
                }}
              />
              <div
                style={{
                  width: 416,
                  height: 198,
                  borderRadius: 57,
                  backgroundColor: '#FFBB7C',
                  position: 'absolute',
                  right: -75,
                  bottom: 329,
                }}
              />

              {markets?.map((market) => (
                <motion.div
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    top: market?.defaultTop,
                    left: market?.defaultLeft,
                  }}
                  animate={{
                    x: market?.left,
                    y: market?.top,
                  }}
                  transition={{ duration: 2.5 }}
                >
                  <img src={market.MarketIconUrl} style={{ width: 40 }} />
                </motion.div>
              ))}

              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  left: 30,
                  top: 130,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.50)',
                  position: 'absolute',
                  left: 160,
                  bottom: 130,
                }}
                className="backdropFilter27"
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  right: 160,
                  top: 350,
                }}
                className="backdropFilter15"
              />

              <div
                style={{
                  fontSize: 80,
                  fontWeight: 800,
                  lineHeight: '100%',
                  textAlign: 'center',
                  zIndex: 1,
                  backgroundColor: '#F5F5F5',
                  borderRadius: 12,
                }}
              >
                <div
                  style={{
                    padding: 10,
                    marginBottom: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {titleComponentVisible ? (
                    pcTitleComponentList[titleComponentIndex]
                  ) : (
                    <div style={{ width: 158.28 }} />
                  )}
                  <span>데이터 단숨에 연결</span>
                </div>
                <div>AI 전략을 더해 단번에 성장</div>
              </div>

              <div
                className="flex"
                style={{ gap: 10, marginTop: 58, alignItems: 'center', zIndex: 1 }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt="mobile-home-google-download"
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt="mobile-home-apple-download"
                />
                {isEnteredByNaver ? (
                  <img src="images/QrDownloadByNaver.png" style={{ width: 78 }} alt="QR" />
                ) : is3o3Promotion ? (
                  <img src="images/QrDownloadBy3o3.png" style={{ width: 78 }} alt="QR" />
                ) : (
                  <img src="images/QrDownload.png" style={{ width: 78 }} alt="QR" />
                )}
              </div>

              {is3o3Promotion && (
                <img
                  src="/images/pc3o3Logo.png"
                  style={{ width: 850, zIndex: 0, marginTop: 36 }}
                  alt="3o3Logo"
                />
              )}

              <div style={{ height: 489 }} />
            </div>

            <div style={{ fontSize: 40, fontWeight: 700, lineHeight: '130%', color: '#1A1A1A' }}>
              아직도 고민하고 계신가요?
            </div>

            <div
              style={{
                marginTop: 60,
                marginBottom: 170,
                fontSize: 60,
                fontWeight: 700,
                lineHeight: '130%',
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
                color: '#DFDFDF',
              }}
            >
              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: 0,
                    width: 2235 * 4,
                    whiteSpace: 'nowrap',
                    animation: 'carousel 12s linear infinite',
                    gap: 0,
                    margin: 0,
                  }}
                >
                  <div style={{ width: '2235px' }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요. 새로고침 하지 않고도 <span style={{ color: '#5B9DFF' }}>주문</span>을{' '}
                    <span style={{ color: '#5B9DFF' }}>바로 확인</span>하고 싶어요.{' '}
                  </div>
                  <div style={{ width: '2235px' }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요. 새로고침 하지 않고도 <span style={{ color: '#5B9DFF' }}>주문</span>을{' '}
                    <span style={{ color: '#5B9DFF' }}>바로 확인</span>하고 싶어요.{' '}
                  </div>
                  <div style={{ width: '2235px' }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요. 새로고침 하지 않고도 <span style={{ color: '#5B9DFF' }}>주문</span>을{' '}
                    <span style={{ color: '#5B9DFF' }}>바로 확인</span>하고 싶어요.{' '}
                  </div>
                  <div style={{ width: '2235px' }}>
                    <span style={{ color: '#FF7171' }}>반복 로그인 없이</span> 데이터를 확인하고
                    싶어요. 새로고침 하지 않고도 <span style={{ color: '#5B9DFF' }}>주문</span>을{' '}
                    <span style={{ color: '#5B9DFF' }}>바로 확인</span>하고 싶어요.{' '}
                  </div>
                </div>
              </div>

              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: 2074 * 4,
                    padding: 0,
                    whiteSpace: 'nowrap',
                    animation: 'carousel 11.022s linear infinite',
                  }}
                >
                  <div style={{ width: '2074px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어. 스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게
                    너무 번거로워.{' '}
                  </div>
                  <div style={{ width: '2074px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어. 스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게
                    너무 번거로워.{' '}
                  </div>
                  <div style={{ width: '2074px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어. 스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게
                    너무 번거로워.{' '}
                  </div>
                  <div style={{ width: '2074px' }}>
                    일일이 계산하지 않고 <span style={{ color: '#FF7171' }}>오늘 입금액</span>을
                    알고 싶어. 스토어 별로 <span style={{ color: '#5B9DFF' }}>리뷰</span>를 다는게
                    너무 번거로워.{' '}
                  </div>
                </div>
              </div>
              <div
                className="flexRowWrapper"
                style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: 1914 * 4,
                    padding: 0,
                    whiteSpace: 'nowrap',
                    animation: 'carousel 10.166s linear infinite',
                  }}
                >
                  <div style={{ width: '1914px' }}>
                    실시간으로 스토어에 대한 유료 프로그램은 부담스러운데{' '}
                    <span style={{ color: '#F6C518' }}>무료</span> 프로그램은 없을까?{' '}
                  </div>
                  <div style={{ width: '1914px' }}>
                    실시간으로 스토어에 대한 유료 프로그램은 부담스러운데{' '}
                    <span style={{ color: '#F6C518' }}>무료</span> 프로그램은 없을까?{' '}
                  </div>
                  <div style={{ width: '1914px' }}>
                    실시간으로 스토어에 대한 유료 프로그램은 부담스러운데{' '}
                    <span style={{ color: '#F6C518' }}>무료</span> 프로그램은 없을까?{' '}
                  </div>
                  <div style={{ width: '1914px' }}>
                    실시간으로 스토어에 대한 유료 프로그램은 부담스러운데{' '}
                    <span style={{ color: '#F6C518' }}>무료</span> 프로그램은 없을까?{' '}
                  </div>
                </div>
              </div>
            </div>
          </MobileContentGrayWrapperWithNoPadding>

          <MobileContentWhiteWrapperWithNoPadding>
            <div style={{ height: 140 }} />
            <div style={{ fontSize: 40, fontWeight: 700, lineHeight: '130%', color: '#1A1A1A' }}>
              <span style={{ color: '#FF7F00' }}>셀러박스</span>가 셀러님 고민 없애드릴게요
            </div>
            <div style={{ height: 60 }} />
            <div
              style={{
                width: 1200,
                height: 600,
                display: 'flex',
                borderRadius: 40,
                backgroundColor: '#F9F9FA',
              }}
            >
              <div style={{ marginLeft: 60, marginTop: 60 }}>
                <PcTextBox
                  style={{ width: 365 }}
                  titleStyle={{ fontSize: 40 }}
                  title={`마켓마다 로그인없이\n앱에서 모두 확인하세요`}
                  description={`언제 어디서든 셀러박스에서\n모든 정보를 살펴보세요`}
                />
              </div>
              <img
                src="images/pcSellerboxAppMainExampleImage.png"
                style={{ height: '100%' }}
                alt="img"
              />
            </div>
            <div style={{ height: 220 }} />
            <PcDetailBox
              title={`주문이 들어오면\n실시간 알림을 보내드려요`}
              description={`연결한 마켓에서 주문이 왔을 때\n셀러박스는 놓치지 않고 알려드려요`}
              image="images/pcSellerboxAppScreen1ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 140 }} />
            <PcDetailBox
              title={`오늘 입금되는 돈\n오전 9시에 알려드려요`}
              description={`마켓마다 다른 정산 주기\n월별 입금액을 미리 확인하세요`}
              image="images/pcSellerboxAppScreen2ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 140 }} />
            <PcDetailBox
              title={`똑같은 리뷰 답글은 그만\nAI 답변으로 재구매를 높여요`}
              description={`구매자의 긍정표현, 부정표현을 분석하고\nAI가 적절한 답변을 생성해드려요`}
              image="images/pcSellerboxAppScreen3ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 140 }} />
            <PcDetailBox
              title={`잦은 반품과 취소\n원인을 분석하고 대응하세요`}
              description={`유쾌하지 않은 반품과 취소\n구매자 사유를 통계로 보여드려요`}
              image="images/pcSellerboxAppScreen4ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 140 }} />
            <PcDetailBox
              title={`기간별 판매금액과 건수\n한 눈에 확인하세요`}
              description={`1주, 1개월, 3개월 판매 추이를 확인하고\n다른 셀러와 비교해보세요`}
              image="images/pcSellerboxAppScreen5ExampleImage.png"
              type="leftText"
            />
            <div style={{ height: 140 }} />
            <PcDetailBox
              title={`매일 변하는 키워드 별\n노출 순위를 확인하세요`}
              description={`상품의 순위 변동을 실시간으로 추적하고,\n시장 변화에 능동적으로 대응하세요`}
              image="images/pcSellerboxAppScreen6ExampleImage.png"
              type="rightText"
            />
            <div style={{ height: 200 }} />
            <div
              style={{
                width: 1290,
                padding: '94px 137px 124px 137px',
                borderRadius: 48,
                backgroundColor: '#F9F9F9',
                textAlign: 'center',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}
              >
                <img src="images/IconMarket.png" style={{ width: 40 }} alt="img" />
                <div
                  style={{ fontSize: 36, fontWeight: 700, lineHeight: '100%', color: '#3D3D3D' }}
                >
                  연동 가능한 상점
                </div>
                <div
                  style={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', color: '#939393' }}
                >
                  연동 가능 스토어를 지속적으로 확대 중입니다!
                </div>
              </div>
              <div style={{ height: 70 }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 24,
                  justifyContent: 'center',
                }}
              >
                {markets.map((market, marketIndex) => (
                  <div key={marketIndex} style={{ textAlign: 'center' }}>
                    <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} alt="logo" />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                        lineHeight: '22px',
                        color: '#939393',
                      }}
                    >
                      {market.MarketName.split(' (')[0]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ height: 200 }} />
            <div style={{ width: 1290 }}>
              <PcTextBox
                style={{ width: 365 }}
                title={`더 많은 기능`}
                description={`셀러박스에서 더 많은 기능들을\n직접 이용해 보세요`}
              />
              <div style={{ height: 60 }} />
              <AnimationDiv>
                <img
                  src="images/pcSellerboxAppMoreFuntionList1Image.png"
                  style={{ width: 1290 }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/pcSellerboxAppMoreFuntionList2Image.png"
                  style={{ width: 1290 }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 46 }} />
              <AnimationDiv>
                <img
                  src="images/pcSellerboxAppMoreFuntionList3Image.png"
                  style={{ width: 1290 }}
                  alt=""
                />
              </AnimationDiv>
              <div style={{ height: 148 }} />
            </div>
          </MobileContentWhiteWrapperWithNoPadding>

          <MobileContentBlackWrapperWithNoPadding>
            <div
              style={{
                width: 1290,
                height: 280,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {reviewVisible && (
                <motion.div
                  style={{ position: 'relative', left: -100 }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    x: 100,
                    opacity: 1,
                  }}
                  transition={{ duration: 0.7 }}
                >
                  <div>
                    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                      <img src="images/unkownUserImage.png" style={{ width: 36 }} alt="" />
                      <div
                        style={{ color: '#FFF', fontSize: 16, fontWeight: 500, lineHeight: '160%' }}
                      >
                        {reviewList[reviewPage].title}
                      </div>
                    </div>
                    <div style={{ height: 12 }} />
                    <div
                      style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 500,
                        lineHeight: '160%',
                        whiteSpace: 'pre-wrap',
                        paddingRight: 20,
                      }}
                    >
                      {reviewList[reviewPage].description}
                    </div>
                  </div>
                </motion.div>
              )}
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderRightWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 0 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === 0 ? reviewList.length - 1 : preReviewPage - 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(0);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewBackArrowImage.png"
                    style={{ width: 12 }}
                    alt=""
                  />
                </div>
                <div style={{ height: 48, width: 1, backgroundColor: '#C8C8C8' }} />
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderLeftWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 1 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === reviewList.length - 1 ? 0 : preReviewPage + 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(1);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewForwardArrowImage.png"
                    style={{ width: 12 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </MobileContentBlackWrapperWithNoPadding>

          <MobileContentGrayWrapperWithNoPadding>
            <div
              style={{
                width: '100%',
                height: 968,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  backgroundColor: '#FF9C66',
                  position: 'absolute',
                  left: -43,
                  top: 87,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  left: 53,
                  top: 267,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 416,
                  height: 198,
                  borderRadius: 57,
                  backgroundColor: '#FFBB7C',
                  position: 'absolute',
                  right: -213,
                  bottom: 309,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  right: 53,
                  top: 309,
                }}
                className="backdropFilter15"
              />

              <div style={{ height: 190 }} />

              <div style={{ width: 1290, position: 'relative', zIndex: 1 }}>
                <div
                  style={{ fontSize: 44, fontWeight: 880, lineHeight: '130%', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서
                  만들었어요
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{ fontSize: 22, fontWeight: 500, lineHeight: '160%', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 48 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage1.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
                <div style={{ height: 30 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage2.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
              </div>
              <div style={{ height: 200 }} />
            </div>
          </MobileContentGrayWrapperWithNoPadding>
          <MobileContentWhiteWrapperWithNoPadding style={{ backgroundColor: '#FBFBFB' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 76,
                paddingTop: 186,
                paddingBottom: 136,
              }}
            >
              <div style={{ height: 548 }}>
                <div
                  style={{ color: '#3D3D3D', fontSize: 22, fontWeight: 500, lineHeight: '160%' }}
                >
                  셀러박스 키위
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    color: '#1A1A1A',
                    fontSize: 40,
                    fontWeight: 800,
                    lineHeight: '130%',
                  }}
                >
                  {`키워드 검색과\n경쟁상점 분석을 한번에`}
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open('https://sellkey.sellerbox.io', '_self');
                  }}
                >
                  <span
                    style={{
                      color: '#1A1A1A',
                      fontSize: 18,
                      fontWeight: 700,
                      lineHeight: '140%',
                      marginRight: 4,
                    }}
                  >
                    바로가기
                  </span>
                  <img src="images/ShortcutImage.png" style={{ width: 32 }} alt="" />
                </div>
                <div style={{ height: 48 }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                  {sellkeyMenuList.map((menu, menuIndex) => (
                    <div
                      key={menuIndex}
                      onClick={() => {
                        setSelectedMenu(menu);
                        setSellkeyPreviewVisible(false);
                      }}
                      style={{
                        cursor: 'pointer',
                        width: 190,
                        height: 60,
                        borderRadius: 20,
                        paddingLeft: 35,
                        backgroundColor:
                          selectedMenu.title === menu.title ? '#708FFF' : 'transparent',
                        boxShadow:
                          selectedMenu.title === menu.title
                            ? '0px 4px 14px 0px rgba(49, 76, 175, 0.25)'
                            : 'none',
                        color: selectedMenu.title === menu.title ? '#FFFFFF' : '#3D3D3D',
                        fontWeight: selectedMenu.title === menu.title ? 700 : 500,
                        fontSize: 22,
                        lineHeight: '160%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      {menu.title}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {sellkeyPreviewVisible ? (
                  <AnimationDivJustOpacity>
                    <img
                      src={selectedMenu.pcImage}
                      style={{
                        borderRadius: 21.545,
                        width: 850,
                        boxShadow: '-12.70349px 15.87936px 23.28973px 0px rgba(0, 0, 0, 0.05)',
                      }}
                      alt=""
                    />
                  </AnimationDivJustOpacity>
                ) : (
                  <div style={{ width: 850, height: 544 }} />
                )}
              </div>
            </div>
          </MobileContentWhiteWrapperWithNoPadding>

          <div className="flexRowWrapper" style={{ width: '100%', position: 'relative' }}>
            <img
              src="images/pc-home-last-background.png"
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 600,
                position: 'absolute',
                zIndex: 0,
              }}
              alt=""
            />
            <div
              style={{
                width: '100%',
                height: 600,
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 28, fontWeight: 700, lineHeight: '150%', color: '#DFDFDF' }}>
                판매의 성장을 스마트하게
              </div>
              <div style={{ height: 12 }} />
              <div style={{ fontSize: 48, fontWeight: 700, lineHeight: '150%', color: '#F5F5F5' }}>
                셀러박스와 함께 시작해 보세요
              </div>
              <div
                className="flex"
                style={{
                  gap: 10,
                  marginTop: 16,
                  marginBottom: 40,
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt=""
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt=""
                />
                {isEnteredByNaver ? (
                  <img src="images/QrDownloadByNaver.png" style={{ width: 78 }} alt="" />
                ) : is3o3Promotion ? (
                  <img src="images/QrDownloadBy3o3.png" style={{ width: 78 }} alt="" />
                ) : (
                  <img src="images/QrDownload.png" style={{ width: 78 }} alt="" />
                )}
              </div>
              <img
                className="pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push('/clients/contact');
                }}
                alt=""
                src="images/pc-home-inquiry-button.png"
                style={{ width: 486 }}
              />
            </div>
          </div>
        </PcPageWrapper>
      )}
    </WholePageWrapper>
  );
};

export const WholePageWrapper = styled.div`
  min-width: 100%;
  // padding-top: 70px;
  /* min-width: 1440px; */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow-x: hidden;
`;

export const MobilePageWrapper = styled.div`
  min-height: calc(100vh - ${mobileAppBarHeight}px);
  overflow: hidden;
  width: 100%;
`;

export const MobileContentGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f5f5f5;
`;

export const MobileContentGrayWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px;
  background-color: #f5f5f5;
`;

export const MobileContentLightGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f9f9f9;
`;

export const MobileContentLightWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #fcfcfc;
`;

export const MobileContentLightWhiteWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #fcfcfc;
`;

export const MobileContentWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #ffffff;
`;

export const MobileContentWhiteWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #fff;
`;

export const MobileContentBlackWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #47474d;
`;

export const PcPageWrapper = styled.div`
  min-height: calc(100vh - ${webAppBarHeight}px);
  /* width: 1440px; */
  width: 100%;
  // padding-bottom: 120px;
  min-width: 1440px;
  /* margin-top: 70px; */
  /* max-width: 1440px; */
`;

export const MainDetailDescriptionTextLeft = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;

export const MainDetailDescriptionTextRight = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;
