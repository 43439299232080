import { Backdrop, Button, styled, ThemeProvider, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  appDownloadUrl,
  clientsUrl,
  companyUrl,
  ContactUrl,
  logout,
  noticeUrl,
  recruitmentUrl,
  serviceUrl,
  signIn,
} from '../constants/urls';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';
import { DarkTheme } from '../themes/DarkTheme';
import { MainTheme } from '../themes/MainTheme';
import { MenuButton } from '../widgets/MenuButton';
import { mobileAppBarHeight, MyAppBar, webAppBarHeight } from '../widgets/MyAppBar';
import { MyMenu } from '../widgets/MyMenu';
import { MyMenuButton } from '../widgets/MyMenuButton';
import { MyMenuItem } from '../widgets/MyMenuItem';
import { faqUrl } from '../constants/urls';
import { ReactComponent as SellerLogo } from '../assets/images/SellerLogo.svg';
import { useScrollEvent } from '../hooks/useScrollEvent';
import { useLayoutSize } from '../hooks/useLayoutSize';
import { useResizeDetector } from 'react-resize-detector';
import { useStoreValue } from '../contexts';

type MenuType = {
  url: string;
  label: string;
  isRedirection?: boolean;
};

const registeredMenuItems: MenuType[] = [
  {
    url: serviceUrl,
    label: '셀러박스 APP',
    isRedirection: false,
  },
  {
    url: 'https://app.sellerbox.io?tab=phone',
    label: '셀러박스 PC',
    isRedirection: true,
  },
  {
    url: 'https://sellkey.sellerbox.io',
    label: '셀키',
    isRedirection: true,
  },
  {
    url: 'https://cplat.io/',
    label: '회사소개',
    isRedirection: true,
  },
  {
    url: 'https://blog.sellerbox.io/',
    label: '블로그',
    isRedirection: true,
  },
];

const SubMenuItems: MenuType[] = [
  {
    url: noticeUrl,
    label: '공지사항',
    isRedirection: false,
  },

  {
    url: faqUrl,
    label: '자주하는 질문',
    isRedirection: false,
  },
  {
    url: ContactUrl,
    label: '문의하기',
    isRedirection: false,
  },
];

export interface IMenuBarProps {}

export function MenuBar({}: IMenuBarProps) {
  const [showMenu, setShowMenu] = React.useState(true);
  const { height: layoutHeight } = useLayoutSize();
  useScrollEvent({
    onScrollUp: (y) => {
      setShowMenu(true);
    },
    onScrollDown: (y) => {
      if (!y) return;
      setShowMenu(y < layoutHeight * 1.5);
    },
  });

  const history = useHistory();
  const { pathname } = useLocation();
  const xsMatches = useMediaQueryForMobile();

  const goUrl = React.useCallback(
    (url: string) => () => {
      history.push(url);
    },
    [history]
  );

  const isDarkTheme = pathname === recruitmentUrl;
  const width = window.innerWidth;

  return (
    // <MyAppBar position={"fixed" : "absolute"} borderStyle={!isDarkTheme}>
    <MyAppBar
      // style={{
      //   transform: showMenu
      //     ? "translateY(0px)"
      //     : `translateY(-${webAppBarHeight}px)`,
      // }}
      // position={"fixed"}
      borderStyle={!isDarkTheme}
    >
      <ThemeProvider theme={isDarkTheme ? DarkTheme : MainTheme}>
        <div
          style={{
            maxWidth: 1440,
            width: (width || 0) > 1440 ? 1440 : '100%',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'relative',
          }}
        >
          <Toolbar
            style={{
              position: 'relative',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            {!xsMatches ? (
              <WebMenuBar goUrl={goUrl} isDarkMode={isDarkTheme} />
            ) : (
              <MobileMenuBar goUrl={goUrl} isDarkMode={isDarkTheme} />
            )}
          </Toolbar>
        </div>
      </ThemeProvider>
    </MyAppBar>
  );
}

interface IMenuBarButtonProps {
  isDarkMode: boolean;
  goUrl: (url: string) => () => void;
}
const WebMenuBar: React.FC<IMenuBarButtonProps> = ({ isDarkMode, goUrl }) => {
  const { userInfo } = useStoreValue();
  const signedIn = Object.keys(userInfo).length === 0 ? false : true;
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const subMenuOpen = Boolean(anchorEl);
  const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClickUrl = (item: MenuType) => () => {
    handleSubMenuClose();
    if (item.isRedirection) {
      window.open(item.url);
    } else {
      window.scrollTo({
        top: 0,
      });
      goUrl(item.url)();
    }
  };

  return (
    <>
      <StyledMainLogoButton
        onClick={() => {
          goUrl('/services')();
          // window.scrollTo({
          //   top: 0,
          // });
        }}
      >
        <SellerLogo />
        <Typography
          variant="h7"
          style={{
            marginLeft: 5,
            marginTop: 2,
            color: isDarkMode ? 'white' : '',
          }}
        ></Typography>
      </StyledMainLogoButton>

      <ButtonWrapper>
        {registeredMenuItems.map((item, idx) => (
          <MyMenuButton
            key={idx}
            onClick={handleClickUrl(item)}
            clicked={pathname.indexOf(item.url) !== -1}
          >
            <Typography variant="h7">{item.label}</Typography>
          </MyMenuButton>
        ))}
        <MyMenuButton
          onClick={goUrl(clientsUrl)}
          onMouseOver={handleSubMenuOpen}
          clicked={pathname === clientsUrl}
        >
          <Typography variant="h7">{'고객센터'}</Typography>
        </MyMenuButton>
        <MyMenu anchorEl={anchorEl} open={subMenuOpen} onClose={handleSubMenuClose}>
          {SubMenuItems.map((menu, idx) => (
            <MyMenuItem onClick={handleClickUrl(menu)} key={idx}>
              <Typography textAlign="center" variant={'h7'} color={'#1C1C1C'}>
                {menu.label}
              </Typography>
            </MyMenuItem>
          ))}
        </MyMenu>
      </ButtonWrapper>
    </>
  );
};

const MobileMenuBar: React.FC<IMenuBarButtonProps> = ({ isDarkMode, goUrl }) => {
  const { userInfo } = useStoreValue();
  const signedIn = Object.keys(userInfo).length === 0 ? false : true;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const handleSubmenuToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleClickUrl = (item: MenuType) => () => {
    handleMenuClose();
    setSubMenuOpen(false);
    if (item.isRedirection === true) {
      window.open(item.url);
    } else {
      goUrl(item.url)();
      // window.scrollTo({
      //   top: 0,
      // });
    }
  };

  return (
    <>
      <StyledMainLogoButton
        style={{ marginLeft: 4 }}
        onClick={() => {
          goUrl('/service')();
        }}
        // clicked={pathname === "/"}
      >
        <SellerLogo />
        <Typography
          variant="h6"
          style={{
            marginLeft: 10,
            marginTop: 2,
            color: isDarkMode ? 'white' : '',
          }}
        ></Typography>
      </StyledMainLogoButton>

      <MenuButton
        isDarkMode={isDarkMode}
        style={{ position: 'absolute', right: 0, top: 0, padding: 0 }}
        clicked={menuOpen}
        onClick={handleMenuOpen}
      />
      <Backdrop
        className={'ddd'}
        open={menuOpen}
        sx={{
          top: mobileAppBarHeight,
          height: '100vh',
        }}
      >
        <MobileMenu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          style={{ left: 0 }}
          className={subMenuOpen ? 'customeropen' : ''}
        >
          {registeredMenuItems.map((item, idx) => (
            <MobileMenuItem onClick={handleClickUrl(item)}>
              <Typography textAlign="center" variant={'h7'} color={'#1C1C1C'}>
                {item.label}
              </Typography>
            </MobileMenuItem>
          ))}
          <MobileMenuItem className={subMenuOpen ? 'clicked' : ''} onClick={handleSubmenuToggle}>
            <Typography variant={'h7'} color={'#1C1C1C'}>
              {'고객센터'}
            </Typography>
          </MobileMenuItem>
          {subMenuOpen &&
            SubMenuItems.map((menu, idx) => (
              <SmallMobileMenuItem key={idx} onClick={handleClickUrl(menu)}>
                <Typography variant={'h12'} color={'#8C8C8C'}>
                  {menu.label}
                </Typography>
              </SmallMobileMenuItem>
            ))}
        </MobileMenu>
      </Backdrop>
    </>
  );
};

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: 'auto',
  gap: 60,
});

const MobileMenu = styled(MyMenu)(({}) => ({
  '& .MuiPaper-root': {
    width: '100vw',
    height: 314,

    maxWidth: '100vw',
    left: '0px !important',
    boxShadow: 'none',
    borderRadius: 0,
    transition:
      'opacity 315ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 210ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 200ms ease-in-out !important',
  },
  '&.customeropen .MuiPaper-root': {
    height: 500,
  },
}));

const MobileMenuItem = styled(MyMenuItem)(({ theme }) => ({
  width: '100vw',
  height: 60,
  paddingLeft: 49,
  justifyContent: 'flex-start',
  '& .MuiTypography-root': {
    borderBottom: '2px solid transparent',
    transition: `border-bottom-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  },
  '&.clicked': {
    '& .MuiTypography-root': {
      borderBottomColor: theme.palette.primary.main,
    },
  },
}));

const SmallMobileMenuItem = styled(MobileMenuItem)({
  height: 20,
  paddingLeft: 55,
});

export const StyledMainLogoButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  // marginLeft: "13.35%",
  // marginRight: "16%",
  display: 'flex',
  flexDirection: 'row',
  //버튼 클릭시 배경 뭐해줄지
  // "&:hover": {
  //   background: "#E2E2E2",
  //   color: "#6F6F6F",
  // },
}));
